import { Big } from 'big.js'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

const DEFAULT_LOCK_PERIOD = '30 days'
const DEFAULT_LOCK_THRESHOLD = 1000

export const useLockInfo = () => {
  const { userInfo, dobContractInfo, nextWorkTimestamp } = toRefs(useDOBStore())
  const { t } = useCustomI18n()
  const { divDecimals, getOriginalSymbol } = useTokens()
  const { isConnected } = useWallet()

  const dobSymbol = computed(() => getOriginalSymbol('DOB'))

  const lockPeriodString = computed(() => {
    const lockTime = Number(dobContractInfo.value.extendLockDurations)

    if (!lockTime) return DEFAULT_LOCK_PERIOD

    if (lockTime < 60) {
      return t('dynamic.seconds', {
        value: dayjs.duration({ seconds: lockTime }).asSeconds(),
      })
    }
    if (lockTime < 3600) {
      return t('dynamic.minutes', {
        value: dayjs.duration({ seconds: lockTime }).asMinutes(),
      })
    }
    if (lockTime < 86400) {
      return t('dynamic.hours', {
        value: dayjs.duration({ seconds: lockTime }).asHours(),
      })
    }
    return t('dynamic.days', {
      value: dayjs.duration({ seconds: lockTime }).asDays(),
    })
  })

  const lockThreshold = computed(() => {
    if (dobContractInfo.value.bulletRewardThreshold === '0')
      return DEFAULT_LOCK_THRESHOLD
    return Number(
      divDecimals(dobContractInfo.value.bulletRewardThreshold, 'DOB').value,
    )
  })

  const lockThresholdString = computed(() => {
    const formattedLockThreshold = useTokenNumberFormat(lockThreshold.value, {
      token: 'DOB',
    })
    return `${formattedLockThreshold} ${dobSymbol.value}`
  })

  const userLockingPeriodString = computed(() => {
    if (!Number(userInfo.value.lastEntryTimestamp)) return 'None'

    const startTimestamp = Number(userInfo.value.lastEntryTimestamp + '000')
    const period = Number(dobContractInfo.value.extendLockDurations + '000')

    const start = dayjs(new Date(startTimestamp)).format('D MMM')
    const end = dayjs(new Date(startTimestamp + period)).format('D MMM')

    return `${start} - ${end}`
  })

  const countdownValue = ref(0)

  onMounted(() => {
    const interval = setInterval(() => {
      const newValue = (Number(nextWorkTimestamp.value) - Date.now()) / 1000
      countdownValue.value = newValue <= 0 ? 0 : newValue
    }, 1000)
    return () => clearInterval(interval)
  })

  const formatSeconds = (secs: number) => {
    if (secs <= 0) return '00:00:00'
    const pad = (n: number) =>
      n < 10 ? (n < 0 ? '-' : '') + `0${Math.abs(n)}` : n

    const h = Math.floor(secs / 3600)
    const m = Math.floor(secs / 60) - h * 60
    const s = Math.floor(secs - h * 3600 - m * 60)

    return `${pad(h)}:${pad(m)}:${pad(s)}`
  }

  const nextRaffleCountdown = computed(() => {
    return formatSeconds(countdownValue.value)
  })

  const isWithdrawEnabled = computed(() => {
    if (!isConnected.value) return false

    const duration = Number(dobContractInfo.value.extendLockDurations)
    const userLockedAmount = Number(userInfo.value.totalStakingAmount)
    const userLockedTimestamp = Number(userInfo.value.lastEntryTimestamp)
    const currentTime = Date.now() / 1000

    return (
      Big(userLockedAmount).gt(0) &&
      Big(currentTime).gt(userLockedTimestamp + duration)
    )
  })

  return {
    dobSymbol,
    lockPeriodString,
    lockThreshold,
    lockThresholdString,
    nextRaffleCountdown,
    userLockingPeriodString,
    isWithdrawEnabled,
  }
}
